import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { StoreState } from "../redux/reduxStore.ts";
import { ProductData } from "../services/product/types.ts";
import ProductCounter from "./ProductCounter.tsx";

type IProps = {
  onPress: () => void;
  src: string;
  product: ProductData;
  onPressAdd: () => void;
  onPressdec: () => void;
};

const ProductCardHorizontal: React.FunctionComponent<IProps> = ({
  onPress,
  onPressdec,
  src,
  product,
  onPressAdd,
}) => {
  const cartData = useSelector((state: StoreState) => state.cart);
  const [isProductInCart, setIsProductInCart] = useState<
    { productData: ProductData; quantity: number } | undefined
  >(undefined);

  useEffect(() => {
    const index = cartData.cartProducts.findIndex(
      (item) => item.productData.id === product.id
    );
    if (index > -1) setIsProductInCart(cartData.cartProducts[index]);
    else setIsProductInCart(undefined);
  }, [cartData.cartProducts, product.id]);

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        margin: 2,
        padding: 1,
        borderRadius: 2,
        boxShadow: 3,
        cursor: "pointer",
      }}
      onClick={onPress}
    >
      <CardMedia
        component="img"
        image={src}
        alt={product.name}
        sx={{
          width: 100,
          height: 100,
          borderRadius: 1,
        }}
      />
      <CardContent
        sx={{
          flex: 1,
          paddingLeft: 2,
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", color: "primary.main" }}
        >
          {product.name}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary", mt: 1 }}>
          {product.description}
        </Typography>
      </CardContent>
      {isProductInCart ? (
        <Box sx={{ marginRight: 2 }}>
          <ProductCounter
            quantity={isProductInCart.quantity}
            onPressIncrease={onPressAdd}
            onPressDecrease={onPressdec}
          />
        </Box>
      ) : (
        <Box sx={{ marginRight: 2 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: "50%", width: 50, height: 60 }}
            onClick={onPressAdd}
          >
            <AddIcon />
          </Button>
        </Box>
      )}
    </Card>
  );
};

export default ProductCardHorizontal;
