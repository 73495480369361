import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Container,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AuthImagePicker from "../commponents/AuthImagePicker.tsx"; // Update the import paths if necessary
import DropDownSelector from "../commponents/DropDownSelector.tsx"; // Update the import paths if necessary
import { isValidEmail } from "../utils/helpers.ts";
import { signUp, signUpIdividual } from "../services/auth/services.ts";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import images from "../constant/images.ts";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { useNavigate } from "react-router-dom";

const initialItemsRole = [
  { id: 1, name: "Individual", selected: true },
  { id: 2, name: "Business", selected: false },
];

const initialItemsLicenece = [
  { id: 1, name: "Select", selected: true },
  { id: 2, name: "Class A", selected: false },
  { id: 3, name: "Class B", selected: false },
];

const initialError = {
  errorExpDate: "",
  errorImage: "",
  errorName: "",
  errorEmail: "",
  errorPassword: "",
  errorConfirmPassword: "",
  errorLiceneceNumber: "",
  errorRole: "",
  errorLicenece: "",
  errorPhone: "",
};

const SignUp = () => {
  //   const navigate = useNavigate();
  const [userData, setUserData] = useState({
    expDate: null,
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    liceneceNumber: "",
    role: "Individual",
    licenece: "Select",
    phoneNo: "",
    imageData: {
      uri: "",
      type: "",
      name: "",
      path: "",
      mime: "",
    },
  });
  const [isDateOpen, setIsDateOpen] = useState<boolean>(false);
  const [inputError, setInputError] =
    useState<typeof initialError>(initialError);
  const [itemsRole, setItemsRole] = useState(initialItemsRole);
  const [expandedRole, setExpandedRole] = useState(false);
  const [expandedLicenece, setExpandedLicenece] = useState(false);
  const [itemLicenece, setItemLicenece] = useState(initialItemsLicenece);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [secureTextEntry, setSecureTextEntry] = useState({
    password: true,
    confirmPassword: true,
  });
  const navigate = useNavigate();
  const handleOnChange = (name: string, value: any) => {
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleImagePicker = () => {
    // Implement image picker for web if needed
  };

  const getSelectedItemsText = (itemsData: any[]) => {
    const selectedItems = itemsData.filter((item) => item.selected);
    return selectedItems.length === 0
      ? "Select"
      : selectedItems.map((item) => item.name).join(", ");
  };

  const handleSignUp = async () => {
    let isError = false;
    let errorText = { ...initialError };

    if (userData.firstName.length < 2) {
      errorText.errorName = "Name is too short.";
      isError = true;
    }
    if (!isValidEmail(userData.email)) {
      errorText.errorEmail = "Email is invalid.";
      isError = true;
    }
    if (
      userData.password !== userData.confirmPassword ||
      userData.password.length < 6
    ) {
      errorText.errorConfirmPassword =
        "The password should have a minimum length of 6 characters, and both password fields should match each other.";
      errorText.errorPassword =
        "The password should have a minimum length of 6 characters, and both password fields should match each other.";
      isError = true;
    }
    if (!userData.phoneNo || userData.phoneNo.length !== 10) {
      errorText.errorPhone = "Please Enter Valid Phone Number";
      isError = true;
    }
    if (userData.role === "Business") {
      if (userData.licenece === "Select") {
        errorText.errorLicenece = "Please select a licenece.";
        isError = true;
      } else {
        if (!userData.liceneceNumber) {
          errorText.errorLiceneceNumber = "Licenece is invalid";
          isError = true;
        }
        if (!userData.imageData) {
          errorText.errorImage = "Image is required.";
          isError = true;
        }
        if (!userData.expDate) {
          errorText.errorExpDate = "Invalid expiry date.";
          isError = true;
        }
      }
    }

    if (isError) {
      setInputError(errorText);
      return;
    } else {
      setIsLoading(true);
      try {
        if (userData.role === "Business") {
          const response = await signUp(
            {
              FirstName: userData.firstName,
              LastName: userData.lastName,
              CustomerEmail: userData.email,
              Password: userData.password,
              ConfirmPassword: userData.confirmPassword,
              Phoneno: userData.phoneNo,
              CustomerRole: userData.role,
              LicenseType: userData.licenece,
              LicenseAttachments: {
                uri: userData.imageData?.path,
                type: userData.imageData?.mime,
                name: "licence.jpg",
              },
              LicenseNumber: userData.liceneceNumber,
              ExpiryDate: new Date(userData?.expDate || ""),
            },
            { contentType: "multipart/form-data" }
          );
          if (response?.httpStatusCode === 200) {
            alert(
              `We have received your request. You will be notified via your email (${response.data.customerEmail}). Afterward, you can log in to the Boot Leggers`
            );
            // navigate("SignIn");
          }
        } else {
          await signUpIdividual(
            {
              FirstName: userData.firstName,
              LastName: userData.lastName,
              CustomerEmail: userData.email,
              Password: userData.password,
              ConfirmPassword: userData.confirmPassword,
              Phoneno: userData.phoneNo,
              CustomerRole: userData.role,
            },
            { contentType: "multipart/form-data" }
          );
          alert(`Please Sign in to continue`);
          navigate("/signin");
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const role = getSelectedItemsText(itemsRole);
    handleOnChange("role", role);
  }, [itemsRole]);

  useEffect(() => {
    const licenece = getSelectedItemsText(itemLicenece);
    handleOnChange("licenece", licenece);
  }, [itemLicenece]);

  return (
    <Container
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box sx={{ padding: 2 }}>
        <Box
          component="img"
          src={images.logo}
          sx={{ width: 300, height: "auto", mb: 2 }}
        />
        <Typography variant="h4" gutterBottom>
          Welcome
        </Typography>
        <Typography variant="h6" gutterBottom>
          Create a new Account
        </Typography>

        <TextField
          label="First Name"
          value={userData.firstName}
          onChange={(e) => handleOnChange("firstName", e.target.value)}
          error={!!inputError.errorName}
          helperText={inputError.errorName}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Last Name"
          value={userData.lastName}
          onChange={(e) => handleOnChange("lastName", e.target.value)}
          error={!!inputError.errorName}
          helperText={inputError.errorName}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Email"
          value={userData.email}
          onChange={(e) => handleOnChange("email", e.target.value)}
          error={!!inputError.errorEmail}
          helperText={inputError.errorEmail}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Password"
          type={secureTextEntry.password ? "password" : "text"}
          value={userData.password}
          onChange={(e) => handleOnChange("password", e.target.value)}
          error={!!inputError.errorPassword}
          helperText={inputError.errorPassword}
          fullWidth
          margin="normal"
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() =>
                  setSecureTextEntry({
                    ...secureTextEntry,
                    password: !secureTextEntry.password,
                  })
                }
              >
                {secureTextEntry.password ? "Show" : "Hide"}
              </IconButton>
            ),
          }}
        />

        <TextField
          label="Confirm Password"
          type={secureTextEntry.confirmPassword ? "password" : "text"}
          value={userData.confirmPassword}
          onChange={(e) => handleOnChange("confirmPassword", e.target.value)}
          error={!!inputError.errorConfirmPassword}
          helperText={inputError.errorConfirmPassword}
          fullWidth
          margin="normal"
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() =>
                  setSecureTextEntry({
                    ...secureTextEntry,
                    confirmPassword: !secureTextEntry.confirmPassword,
                  })
                }
              >
                {secureTextEntry.confirmPassword ? "Show" : "Hide"}
              </IconButton>
            ),
          }}
        />

        <TextField
          label="Phone Number"
          value={userData.phoneNo}
          onChange={(e) => handleOnChange("phoneNo", e.target.value)}
          error={!!inputError.errorPhone}
          helperText={inputError.errorPhone}
          fullWidth
          margin="normal"
        />

        <DropDownSelector
          label="Role"
          itemsData={itemsRole}
          setItemsData={setItemsRole}
          expanded={expandedRole}
          setExpanded={setExpandedRole}
        />

        {userData.role === "Business" && (
          <>
            <DropDownSelector
              label="Licenece"
              itemsData={itemLicenece}
              setItemsData={setItemLicenece}
              expanded={expandedLicenece}
              setExpanded={setExpandedLicenece}
            />

            {userData.licenece !== "Select" && (
              <>
                <TextField
                  label="Licenece Number"
                  value={userData.liceneceNumber}
                  onChange={(e) =>
                    handleOnChange("liceneceNumber", e.target.value)
                  }
                  error={!!inputError.errorLiceneceNumber}
                  helperText={inputError.errorLiceneceNumber}
                  fullWidth
                  margin="normal"
                />

                <AuthImagePicker
                  label="License Image"
                  source={userData.imageData ? userData.imageData : undefined}
                  onPress={handleImagePicker}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Expiry Date"
                    value={userData.expDate}
                    onChange={(date) => handleOnChange("expDate", date)}
                    // renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                    open={isDateOpen}
                    onOpen={() => setIsDateOpen(true)}
                    onClose={() => setIsDateOpen(false)}
                    // error={!!inputError.errorExpDate}
                    // helperText={inputError.errorExpDate}
                  />
                </LocalizationProvider>
              </>
            )}
          </>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={handleSignUp}
          disabled={isLoading}
          fullWidth
          sx={{ mt: 2 }}
        >
          {isLoading ? <CircularProgress size={24} /> : "Sign Up"}
        </Button>

        <Box sx={{ mt: 2 }}>
          <Typography variant="body2">
            Already a member?{" "}
            <Button onClick={() => navigate("/signin")} color="primary">
              Sign In
            </Button>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default SignUp;
