import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { StoreDispatch, StoreState } from "../redux/reduxStore.ts";
import { addtoCart, removeFromCart } from "../redux/cart/actions.ts";
import {
  getAllProduct,
  getProductsByCategoryId,
} from "../services/product/services.ts";
import { CategoryProductItem, ProductData } from "../services/product/types.ts";
import ProductCardHorizontal from "../commponents/ProductCardHorizontal.tsx";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CartButton from "../commponents/CartButton.tsx";
import Skeleton from "@mui/material/Skeleton";

const CategoryProduct: React.FC = () => {
  const { id, name } = useParams<{ id: string; name: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<StoreDispatch>();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<CategoryProductItem[]>([]);
  const cartData = useSelector((state: StoreState) => state.cart);

  const addTocart = (product: ProductData) => {
    dispatch(addtoCart({ productData: product, quantity: 1 }))
      .unwrap()
      .then((message) => {
        alert(message);
      });
  };

  const removeQuantity = (product: ProductData) => {
    dispatch(removeFromCart({ productData: product, quantity: 1 }))
      .unwrap()
      .then((message) => {
        alert(message);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (id === "allProducts") {
      getAllProduct({}).then((data: any) => {
        if (data) setProducts(data.data);
        setLoading(false);
      });
    } else {
      if (id)
        getProductsByCategoryId(id, {})
          .then((data: any) => {
            if (data) setProducts(data.data);
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setLoading(false);
          });
    }
  }, [id]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          width: "100%",
          height: 90,
          boxShadow: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 30, left: 20 }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h5"
          sx={{ fontWeight: 600, color: "primary.main" }}
        >
          {name}
        </Typography>
      </Box>

      <Container sx={{ paddingBottom: 0, backgroundColor: "transparent" }}>
        <Grid container spacing={2}>
          {loading ? (
            <Grid item xs={12}>
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={100}
                animation="wave"
                sx={{ borderRadius: 1 }}
              />
            </Grid>
          ) : products.length === 0 ? (
            <Grid item xs={12}>
              <Typography align="center" sx={{ mt: 2 }}>
                No Products Found
              </Typography>
            </Grid>
          ) : (
            products.map((item, index) => (
              <Grid item xs={12} key={index}>
                <ProductCardHorizontal
                  src={
                    item.images
                      ? `https://bootleggersdistribution.net${item?.images[0]?.path}`
                      : ""
                  }
                  onPress={() => navigate(`/product/${item.productId}`)}
                  product={item}
                  onPressAdd={() => addTocart(item)}
                  onPressdec={() => removeQuantity(item)}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Container>
      <CartButton />
    </>
  );
};

export default CategoryProduct;
