import React from "react";
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Icon,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { StoreDispatch } from "../redux/reduxStore.ts";
import { ProductData } from "../services/product/types.ts";
import { addtoCart, removeFromCart } from "../redux/cart/actions.ts";
import ProductCounter from "./ProductCounter.tsx";

interface IProps {
  isCheckOut?: boolean;
  productData: ProductData;
  quantity: number;
  onPressTrash?: () => void;
}

const CartProduct: React.FC<IProps> = ({
  isCheckOut,
  productData,
  quantity,
  onPressTrash,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch<StoreDispatch>();

  const addTocart = (product: ProductData) => {
    dispatch(addtoCart({ productData: product, quantity: 1 }))
      .unwrap()
      .then((message: string) => {
        // Handle toast notifications based on your setup
      });
  };

  const removeQuantity = (product: ProductData) => {
    dispatch(removeFromCart({ productData: product, quantity: 1 }));
  };

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: 2,
        mb: 2,
        p: 1,
        boxShadow: 3,
        border: `1px solid ${theme.palette.grey[300]}`,
        position: "relative",
      }}
    >
      {!isCheckOut && (
        <IconButton
          sx={{ position: "absolute", right: 10, top: 10 }}
          onClick={onPressTrash}
        >
          <DeleteIcon sx={{ color: theme.palette.error.main }} />
        </IconButton>
      )}
      <CardMedia
        component="img"
        sx={{ width: 100, height: 100, borderRadius: 1, m: 1 }}
        image={
          productData?.images
            ? `https://bootleggersdistribution.net${productData?.images[0]?.path}`
            : ""
        }
        alt={productData.name}
      />
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexGrow: 1,
          pl: 2,
        }}
      >
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {productData.name}
          </Typography>
          <Typography variant="body2">{productData.description}</Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          {!isCheckOut ? (
            <ProductCounter
              quantity={quantity}
              onPressIncrease={() => addTocart(productData)}
              onPressDecrease={() => removeQuantity(productData)}
            />
          ) : (
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              X{quantity}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CartProduct;
