import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
  Container,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AuthInput from "../commponents/AuthInput.tsx";
import AppDialog from "../commponents/AppDialouge.tsx"; // Assuming the converted MUI dialog component is named AppDialog
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../services/auth/services.ts";
import { userActions } from "../redux/user/slice.ts";
import images from "../constant/images.ts";
import { isValidEmail } from "../utils/helpers.ts";
import { StoreState } from "../redux/reduxStore.ts";
import { useNavigate } from "react-router-dom";

const userInitialData = {
  email: "",
  password: "",
};

const initialError = {
  errorEmail: "",
  errorPassword: "",
};

const SignIn = () => {
  const theme = useTheme();
  const [userData, setUserData] = useState(userInitialData);
  const [secureTextEntry, setSecureTextEntry] = useState(false);
  const [responseError, setResponseError] = useState("");
  const [inputError, setInputError] = useState(initialError);
  const [loading, setLoading] = useState(false);
  const reduxUser = useSelector((state: StoreState) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOnChange = (name, value) => {
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    let isError = false;
    let errorText = { ...initialError };

    if (!isValidEmail(userData.email)) {
      errorText.errorEmail = "Email is Invalid";
      isError = true;
    }

    if (userData.password.length < 6) {
      errorText.errorPassword =
        "The password should have a minimum length of 6 characters";
      isError = true;
    }

    if (isError) {
      setInputError(errorText);
      return;
    } else {
      setLoading(true);
      signIn(userData.email, userData.password, {})
        .then((response) => {
          if (response.message) {
            alert("The username or password is incorrect");
          }
          if (response && response.error) {
            setResponseError(response.error);
          } else {
            if (
              response?.httpStatusCode === 200 &&
              response.data.customerEmail &&
              response.data.customerName
            ) {
              if (!response.data.isActive && response.data.isDeleted) {
                alert("No Account Exist with this email.");
                return;
              }
              if (response.data.isActive) {
                dispatch(
                  userActions.setUser({
                    accessToken: "wqeqwe",
                    customerEmail: response.data.customerEmail,
                    customerName: response.data.customerName,
                    isLoggedIn: true,
                    customerId: response.data.customerId,
                    phoneno: response.data.phoneno,
                  })
                );
                navigate("/");
              } else {
                alert(
                  "User is not Active, please contact the Administrator at admin@bootleggers.com"
                );
              }
            }
          }
        })
        .catch((err) => {
          alert("The username or password is incorrect");
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Container
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          px: 3,
          pb: 10,
          backgroundColor: "white",
        }}
      >
        <img
          src={images.logo}
          alt="Logo"
          style={{ width: 300, height: "auto" }}
        />
        <Typography variant="h4" sx={{ mb: 2 }}>
          Welcome
        </Typography>
        <Typography sx={{ mb: 6 }}>
          Please login or signup to continue to our Web
        </Typography>
        <AuthInput
          label="Email"
          onChange={(e) => handleOnChange("email", e.target.value)}
          value={userData.email}
          errorMessage={inputError.errorEmail}
        />
        <AuthInput
          label="Password"
          onChange={(e) => handleOnChange("password", e.target.value)}
          value={userData.password}
          secureTextEntry={!secureTextEntry}
          setSecureTextEntry={() => setSecureTextEntry(!secureTextEntry)}
          errorMessage={inputError.errorPassword}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ borderRadius: 2, py: 1.5, mt: 2 }}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Sign In"}
        </Button>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
          <Typography>
            Don't have an account yet?{" "}
            <Button
              onClick={() => navigate("/signup")}
              sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}
            >
              Sign up
            </Button>
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
          <Button
            onClick={() => navigate("/forgot-password")}
            sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}
          >
            Lost your Password?
          </Button>
        </Box>
      </Box>
      <AppDialog
        visible={!!responseError}
        onClose={() => setResponseError("")}
      />
    </Container>
  );
};

export default SignIn;
