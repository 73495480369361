import React from "react";
import { Box, Container, Paper, Typography } from "@mui/material";
import { CategoryItem } from "../services/product/types.ts";
import pic from "../assets/pic.jpg";
import { useNavigate } from "react-router-dom";

interface IProps {
  categories: CategoryItem[];
}

const HomeCategory: React.FC<IProps> = ({ categories }) => {
  const navigate = useNavigate();

  return (
    <Container
      sx={{
        paddingLeft: "15px",
        paddingRight: "15px",
        fontFamily: "Spectral, serif",
        fontSize: "16px",
        fontWeight: 300,
        color: "#000",
        display: "flex",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex", // Flexbox layout
          gap: 2, // Add space between items
          padding: 2,
          fontFamily: "Spectral, serif",
          fontSize: "16px",
          fontWeight: 300,
          color: "rgb(128, 128, 128)",
          lineHeight: "28.8px",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontFamily: "Spectral, serif",
            fontSize: "40px",
            textAlign: "center",
            letterSpacing: "1px",
            my: 1,
            marginTop: "5vh",
            color: "black",
          }}
        >
          Categories
        </Typography>
        <Box display={"flex"} sx={{ overflowX: "scroll", width: "60vw" }}>
          {categories.map((item, index) => (
            <Paper
              key={index}
              data-aos="fade-up"
              sx={{
                padding: 2,
                border: "none",
              }}
              onClick={() =>
                navigate(`/category-product/${item.pCategoryId}/${item.name}`)
              }
              elevation={0}
            >
              <img
                src={pic}
                alt={item.description}
                style={{
                  height: "130px",
                  width: "130px",
                  margin: "0 auto 20px",
                  borderRadius: "50%",
                  backgroundSize: "cover",
                  backgroundPosition: "50% 50%",
                }}
              />
              <Typography
                variant="h3"
                sx={{
                  fontSize: "22px",
                  lineHeight: "33px",
                  color: "rgb(0, 0, 0)",
                  marginBottom: "8px",
                  fontFamily: "Spectral, serif",
                  fontWeight: 300,
                  textAlign: "center",
                  maxWidth: "130px",
                  whiteSpace: "nowrap", // Prevent text from wrapping
                  overflow: "hidden", // Hide overflowed content
                  textOverflow: "ellipsis", // Show ellipsis when the text is truncated
                }}
              >
                {item.description}
              </Typography>
            </Paper>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default HomeCategory;
