import React, { useEffect, useState } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getProductByID } from "../services/product/services.ts";
import images from "../constant/images.ts";
import { OrderItem } from "../services/orders/types.ts";
import { ProductData } from "../services/product/types.ts";
type IProps = {
  orderItem: OrderItem;
};

const OrdersItem: React.FunctionComponent<IProps> = ({ orderItem }) => {
  const theme = useTheme();
  const [productData, setProductData] = useState<ProductData>();

  useEffect(() => {
    getProductByID(orderItem.productId, {})
      .then((data) => {
        if (data) {
          setProductData(data.data);
        }
      })
      .catch((e) => console.error(e));
  }, [orderItem.productId]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 1,
      }}
    >
      <Avatar
        src={
          productData && productData?.images?.length > 0
            ? `https://bootleggersdistribution.net${productData?.images[0]?.path}`
            : images.logo
        }
        sx={{
          width: 80,
          height: 80,
          margin: 1,
          borderRadius: 1,
        }}
        variant="rounded"
      />
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 600,
          color: theme.palette.text.primary,
          textAlign: "center",
        }}
      >
        {orderItem.quantity} x
      </Typography>
    </Box>
  );
};

export default OrdersItem;
