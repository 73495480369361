import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userActions, userSliceIntialState } from "../redux/user/slice.ts";
import { StoreDispatch, StoreState } from "../redux/reduxStore.ts";
import { useTheme } from "@mui/material/styles";
import images from "../constant/images.ts";
import PlaceIcon from "@mui/icons-material/Place";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
const DrawerModal: React.FC = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<StoreDispatch>();
  const userData = useSelector((state: StoreState) => state.user);
  const theme = useTheme();

  useEffect(() => {
    if (userData.drawerOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [userData.drawerOpen]);

  const handleClose = () => {
    setOpen(false);
    dispatch(userActions.setUser({ drawerOpen: false }));
  };

  const handleLogout = () => {
    dispatch(userActions.setUser(userSliceIntialState));
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: 300,
          height: "100%",
          bgcolor: theme.palette.background.default,
          p: 2,
          position: "absolute",
          top: 0,
          left: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          boxShadow: 24,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mb: 3,
          }}
        >
          <img
            src={images.logo}
            alt="Logo"
            style={{ width: 150, height: 150 }}
            onClick={() => navigate("/")}
          />
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Button
            startIcon={<PersonIcon sx={{ width: 20, height: 20 }} />}
            sx={{ justifyContent: "flex-start", mb: 2, textTransform: "none" }}
            onClick={() => {
              handleClose();
              navigate(userData.accessToken ? "/profile" : "/signin");
            }}
          >
            <Typography variant="h6" color="text.primary">
              Profile
            </Typography>
          </Button>

          <Button
            startIcon={<PlaceIcon sx={{ width: 20, height: 20 }} />}
            sx={{ justifyContent: "flex-start", mb: 2, textTransform: "none" }}
            onClick={() => {
              window.open(
                "https://www.google.com/maps/search/?api=1&query=43.0234126,-87.9315113",
                "_blank"
              );
            }}
          >
            <Typography variant="h6" color="text.primary">
              Get Direction
            </Typography>
          </Button>

          <Button
            startIcon={<MenuIcon sx={{ width: 20, height: 20 }} />}
            sx={{ justifyContent: "flex-start", mb: 2, textTransform: "none" }}
            onClick={() => {
              handleClose();
              navigate(userData.accessToken ? "/orders" : "/signin");
            }}
          >
            <Typography variant="h6" color="text.primary">
              Orders & Reordering
            </Typography>
          </Button>

          <Divider sx={{ width: "100%", mb: 2 }} />

          <Button
            startIcon={<LogoutIcon sx={{ width: 20, height: 20 }} />}
            sx={{ justifyContent: "flex-start", textTransform: "none" }}
            onClick={handleLogout}
          >
            <Typography variant="h6" color="text.primary">
              Log Out
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DrawerModal;
