import React, { useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Button,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { StoreDispatch, StoreState } from "../redux/reduxStore.ts";
import { addOrder } from "../services/orders/services.ts";
import { cartActions, cartSliceIntialState } from "../redux/cart/slice.ts";
import { useNavigate } from "react-router-dom";
import OrderSummaryCard from "../commponents/OrderSummaryCard.tsx";
import AppHeading from "../commponents/AppHeading.tsx";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const CheckOut: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const cartData = useSelector((state: StoreState) => state.cart);
  const userData = useSelector((state: StoreState) => state.user);
  const dispatch = useDispatch<StoreDispatch>();

  const orderPrice = [
    { name: "Sub Total", value: cartData.total },
    { name: "Sales Tax (7.9%)", value: cartData.total * 0.079 },
    { name: "Markup/Profit", value: cartData.total * 0.12 },
  ];

  const handlePlaceOrder = () => {
    if (userData.accessToken) {
      addOrder(
        {
          customerId: userData.customerId,
          isCancelled: false,
          orderId: "D3B38D69-0124-489C-A107-EAA88F276794",
          paymentId: "D3B38D69-0124-489C-A107-EAA88F276794",
          totalAmount:
            cartData.total + cartData.total * 0.079 + cartData.total * 0.12,
          orderStatus: "UnPaid",
          createDate: new Date().toISOString(),
          createBy: userData.customerName,
          orderItems: cartData.cartProducts.map((item) => ({
            orderItemId: "D3B38D69-0124-489C-A107-EAA88F276794",
            orderId: "D3B38D69-0124-489C-A107-EAA88F276794",
            productId: String(item.productData.productId),
            quantity: item.quantity,
            price: item.productData.price,
            rowState: 1,
          })),
        },
        {}
      ).then((data: any) => {
        setVisible(true);

        if (data?.httpStatusCode === 200) {
          setVisible(true);
          dispatch(cartActions.setCart(cartSliceIntialState));
        }
      });
    } else {
      navigate("/signIn");
    }
  };

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          width: "100%",
          height: 90,
          boxShadow: theme.shadows[3],
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        {" "}
        <IconButton
          edge="start"
          color="inherit"
          sx={{
            position: "absolute",
            left: theme.spacing(2),
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
          }}
          onClick={() => navigate("/cart")}
        >
          <ArrowBackIcon sx={{ color: "black", fontSize: 25 }} />
        </IconButton>
        <Avatar
          sx={{
            backgroundColor: theme.palette.background.default,
            mt: 1,
            width: 60,
            height: 60,
          }}
          src="/path-to-your-image"
          alt="P"
        />
      </Box>

      <Box sx={{ px: 3, pb: 15 }}>
        <AppHeading heading="Order Summary" viewAll={false} />
        {cartData.cartProducts.map((item, index) => (
          <OrderSummaryCard item={item} key={index} />
        ))}
        <Divider
          sx={{ my: 2 }}
          color={theme.palette.primary.main}
          variant="middle"
        />
      </Box>

      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          backgroundColor: theme.palette.background.paper,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          boxShadow: theme.shadows[5],
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handlePlaceOrder}
          sx={{ width: 150, height: 50 }}
        >
          Place Order
        </Button>
      </Box>

      <Dialog open={visible} onClose={() => setVisible(false)}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              mb: 2,
            }}
          >
            <ShoppingBagIcon
              sx={{
                fontSize: 80,
                color: theme.palette.primary.main,
                mb: 2,
              }}
            />
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
            >
              Order Placed Successfully!
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.primary, textAlign: "center" }}
          >
            Your order has been placed successfully and is ready for pickup.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/home")}
            sx={{ width: 200, height: 50, mx: "auto" }}
          >
            Continue Shopping
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CheckOut;
