import { createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkConfig } from "../reduxStore.ts";
import _ from "lodash";
import { CartSliceType, cartActions } from "./slice.ts";
import { ProductData } from "../../services/product/types.ts";
const UserThunks = {
  addUser: "user/addUser",
};

export const addtoCart = createAsyncThunk<
  string,
  { productData: ProductData; quantity: number },
  AsyncThunkConfig
>(
  UserThunks.addUser,
  async ({ productData, quantity }, { dispatch, getState }) => {
    let message = "Product added";
    const userData = getState().user;
    const cartData = getState().cart;
    let processCartData = _.cloneDeep(cartData);
    const index = processCartData.cartProducts.findIndex(
      (item) => item.productData.id === productData.id
    );
    if (productData.quantity < 0) {
      message = "Item is unavailable";
    }
    if (index > -1) {
      if (
        productData.quantity -
          Number(processCartData.cartProducts[index].quantity || 0) >
        0
      ) {
        processCartData.cartProducts[index].quantity += quantity;
      } else {
        message = `Only ${productData.quantity} left.`;
        return message;
      }
    } else {
      if (productData.quantity > 0)
        processCartData.cartProducts.push({
          productData: productData,
          quantity: quantity,
        });
      else {
        message = "Product out of Stock";
        return message;
      }
    }
    processCartData.total = processCartData.total + productData.price;
    dispatch(cartActions.setCart(processCartData));
    return message;
  }
);

export const removeFromCart = createAsyncThunk<
  string,
  { productData: ProductData; quantity: number },
  AsyncThunkConfig
>(
  UserThunks.addUser,
  async ({ productData, quantity }, { dispatch, getState }) => {
    const message = "Product removed!";

    const userData = getState().user;
    const cartData = getState().cart;
    let processCartData = _.cloneDeep(cartData);
    const index = processCartData.cartProducts.findIndex(
      (item) => item.productData.id === productData.id
    );
    if (index > -1) {
      processCartData.cartProducts[index].quantity -= quantity;
      if (Number(processCartData.cartProducts[index].quantity) <= 0) {
        processCartData.cartProducts = processCartData.cartProducts.filter(
          (item) => item.productData.id !== productData.id
        );
      }
    }
    processCartData.total = processCartData.total - productData.price;
    dispatch(cartActions.setCart(processCartData));
    return message;
  }
);

export const removeItemFromCart = createAsyncThunk<
  string,
  { productData: ProductData },
  AsyncThunkConfig
>(UserThunks.addUser, async ({ productData }, { dispatch, getState }) => {
  const message = "Product removed!";
  const userData = getState().user;
  const cartData = getState().cart;
  let processCartData = _.cloneDeep(cartData);
  const index = processCartData.cartProducts.findIndex(
    (item) => item.productData.id === productData.id
  );
  processCartData.total =
    processCartData.total -
    Number(
      processCartData.cartProducts[index].productData.price *
        processCartData.cartProducts[index].quantity
    );

  if (index > -1) {
    processCartData.cartProducts = processCartData.cartProducts.filter(
      (item) => item.productData.id !== productData.id
    );
  }
  dispatch(cartActions.setCart(processCartData));
  return message;
});
