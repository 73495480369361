import React from "react";
import {
  Box,
  Container,
  Typography,
  Skeleton,
  Card,
  CardContent,
  CardMedia,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ProductData } from "../services/product/types.ts";
import AppProduct from "./AppProduct.tsx";

interface IProps {
  data: ProductData[];
  addTocart: (product: ProductData) => void;
  removeQuantity: (product: ProductData) => void;
}

const HomeProducts: React.FC<IProps> = ({
  data,
  addTocart,
  removeQuantity,
}) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        paddingY: 2,
        gap: 2,
        position: "relative",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "center",
      }}
    >
      {data?.length > 0 ? (
        data?.map((item, index) => (
          <AppProduct
            key={index}
            src={
              item.images
                ? `https://bootleggersdistribution.net${item.images[0]?.path}`
                : ""
            }
            onPress={() => navigate(`/product/${item.productId}`)}
            product={item}
            onPressAdd={() => addTocart(item)}
            onPressDec={() => removeQuantity(item)}
          />
        ))
      ) : (
        <>
          <Card
            sx={{
              width: 150,
              height: 200,
              borderRadius: 2,
              boxShadow: 3,
              marginX: 1,
            }}
          >
            <CardMedia
              component="div"
              sx={{
                height: "100%",
                backgroundColor: "#e0e0e0",
              }}
            >
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                animation="wave"
              />
            </CardMedia>
          </Card>
          <Card
            sx={{
              width: 150,
              height: 200,
              borderRadius: 2,
              boxShadow: 3,
              marginX: 1,
            }}
          >
            <CardMedia
              component="div"
              sx={{
                height: "100%",
                backgroundColor: "#e0e0e0",
              }}
            >
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                animation="wave"
              />
            </CardMedia>
          </Card>
        </>
      )}
    </Box>
  );
};

export default HomeProducts;
const styling = {
  grid: {
    display: "flex",
    paddingY: 2,
    position: "relative",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "center",
  },
  card: {
    width: 150,
    height: 200,
    borderRadius: 2,
    boxShadow: 3,
    marginX: 1,
  },
  cardMedia: {
    height: "100%",
    backgroundColor: "#e0e0e0",
  },
  secondCard: {
    width: 150,
    height: 200,
    borderRadius: 2,
    boxShadow: 3,
    marginX: 1,
  },
  cardMedia2: {
    height: "100%",
    backgroundColor: "#e0e0e0",
  },
};
