import React from "react";
import { Grid, Typography, Link, Box } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import RoomIcon from "@mui/icons-material/Room";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { IoIosArrowForward, IoMdHeart } from "react-icons/io";
import images from "../constant/images.ts";

const Footer = () => {
  const styles = {
    footerContainer: {
      backgroundColor: "#1e1e1e",
      color: "#fff",
      padding: "80px 0px",
      fontFamily: "'Spectral', serif",
    },
    socialIcon: {
      display: "flex",
      flexDirection: { xs: "row", md: "column", lg: "row" },
      gap: 1,
    },
    socialIconWrapper: {
      backgroundColor: "#b7472a",
      padding: "10px",
      width: "20px",
      height: "20px",
      color: "rgba(255, 255, 255, 0.6)",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "5px",
    },
    iconMarginRight: {
      marginTop: "8px",
      fontSize: "14px",
      marginRight: "20px",
      color: "rgba(255, 255, 255, 0.5)",
    },
    gridCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: { xs: "10px", sm: "90px" },
    },
    footerTitle: {
      fontWeight: "bold",
      fontFamily: "'Spectral', serif",
      marginBottom: "30px",
    },
    subtitleLight: {
      color: "rgba(255, 255, 255, 0.6)",
    },
    paragraphText: {
      fontFamily: "'Spectral', serif",
      color: "rgba(255, 255, 255, 0.9)",
      fontSize: "16px",
      width: "160px",
    },
    sectionTitle: {
      fontFamily: "'Spectral', serif",
      marginBottom: "30px",
      fontSize: "20px",
      fontWeight: 400,
      color: "rgba(255, 255, 255, 0.8)",
    },
    linkStyle: {
      color: "rgba(255, 255, 255, 0.6)",
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      marginBottom: "18px",
    },
    arrowIcon: {
      fontSize: "20px",
      marginRight: "5px",
      verticalAlign: "middle",
    },
    questions: {
      marginBottom: "10px",
      fontSize: "16px",
      maxWidth: "220px",
      color: "rgba(255, 255, 255, 0.8)",
      fontFamily: "'Spectral', serif",
    },
    mapIcon: {
      color: "white",
      marginBottom: "40px",
      marginRight: "10px",
    },
    emailStyle: {
      color: "rgba(255, 255, 255, 0.6)",
      fontFamily: "'Spectral', serif",
    },
    footerContainerBottom: {
      width: "100%",
      backgroundColor: "black",
      color: "rgba(255, 255, 255, .5)",
    },
    footerContainerBottomInside: {
      fontFamily: "'Spectral', serif",
      marginLeft: { xs: "10px", sm: "90px", lg: "180px" },
      paddingTop: "50px",
      paddingBottom: "50px",
    },
    footerSpan: {
      color: "white",
    },
    heartIcon: {
      color: "rgba(255, 255, 255, .5)",
      fontSize: "18px",
      verticalAlign: "middle",
    },
    mapLocation: {
      marginBottom: { xs: 0, md: "30px" },
    },
  };

  return (
    <>
      <Box component="footer" sx={styles.footerContainer}>
        <Grid container spacing={4} sx={styles.gridCenter}>
          <Grid item xs={12} md={3}>
            <img
              src={images.logo}
              width={100}
              height={100}
              style={{ objectFit: "contain" }}
            ></img>
            <Typography variant="body2" style={styles.paragraphText} paragraph>
              Far far away, behind the word mountains, far from the countries.
            </Typography>
            <Box sx={styles.socialIcon}>
              <Box sx={styles.socialIconWrapper}>
                <TwitterIcon />
              </Box>
              <Box sx={styles.socialIconWrapper}>
                <FacebookIcon />
              </Box>
              <Box sx={styles.socialIconWrapper}>
                <InstagramIcon />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={2}>
            <Typography variant="h6" style={styles.sectionTitle} gutterBottom>
              My Accounts
            </Typography>
            <Box>
              <Link href="#" style={styles.linkStyle}>
                <IoIosArrowForward style={styles.arrowIcon} /> My Account
              </Link>
              <Link href="#" style={styles.linkStyle}>
                <IoIosArrowForward style={styles.arrowIcon} /> Register
              </Link>
              <Link href="#" style={styles.linkStyle}>
                <IoIosArrowForward style={styles.arrowIcon} /> Log In
              </Link>
              <Link href="#" style={styles.linkStyle}>
                <IoIosArrowForward style={styles.arrowIcon} /> My Order
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} md={2}>
            <Typography variant="h6" style={styles.sectionTitle} gutterBottom>
              Information
            </Typography>
            <Box>
              <Link href="#" style={styles.linkStyle}>
                <IoIosArrowForward style={styles.arrowIcon} /> About us
              </Link>
              <Link href="#" style={styles.linkStyle}>
                <IoIosArrowForward style={styles.arrowIcon} /> Catalog
              </Link>
              <Link href="#" style={styles.linkStyle}>
                <IoIosArrowForward style={styles.arrowIcon} /> Contact us
              </Link>
              <Link href="#" style={styles.linkStyle}>
                <IoIosArrowForward style={styles.arrowIcon} /> Term & Conditions
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography variant="h6" style={styles.sectionTitle} gutterBottom>
              Have a Questions?
            </Typography>
            <Box>
              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                gutterBottom
                style={styles.questions}
              >
                <RoomIcon style={styles.mapIcon} />{" "}
                <span style={styles.mapLocation}>
                  {" "}
                  203 Fake St. Mountain View, San Francisco, California, USA
                </span>
              </Typography>
              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                gutterBottom
              >
                <CallIcon sx={styles.iconMarginRight} />
                <span style={styles.emailStyle}>+2 392 3929 210</span>
              </Typography>
              <Typography variant="body2" display="flex" alignItems="center">
                <EmailIcon sx={styles.iconMarginRight} />{" "}
                <span style={styles.emailStyle}>info@yourdomain.com</span>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box component="footer" sx={styles.footerContainerBottom}>
        <Typography sx={styles.footerContainerBottomInside}>
          Copyright ©2024 All rights reserved | This template is made with{" "}
          <IoMdHeart style={styles.heartIcon} /> by{" "}
          <span style={styles.footerSpan}>Colorlib.com</span>
        </Typography>
      </Box>
    </>
  );
};

export default Footer;
