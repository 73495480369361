import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Container,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import images from "../constant/images.ts";
import { isValidEmail } from "../utils/helpers.ts";

const ForgotPassword = () => {
  //   const navigate = useNavigate();
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const onSubmit = () => {
    if (!isValidEmail(email)) {
      setEmailError("Email is invalid.");
    } else {
      alert("A password reset email has been sent. Please check your email.");
      //   navigate("/SignIn");
    }
  };

  return (
    <Container
      sx={{
        alignItems: "center",
        display: "flex",
        maxWidth: "100%",
        justifyContent: "center",
      }}
    >
      <Box sx={{ padding: 2 }}>
        <Box
          component="img"
          src={images.logo}
          alt="Logo"
          sx={{ width: 300, height: "auto", mb: 2 }}
        />
        <Typography variant="h4" gutterBottom>
          Forgot Password
        </Typography>
        <Typography variant="body1" paragraph>
          Lost your password? Please enter your email address. You will receive
          a link to create a new password via email.
        </Typography>
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!emailError}
          helperText={emailError}
          fullWidth
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          sx={{ mt: 2 }}
        >
          Reset Password
        </Button>
        <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
          <Typography variant="body2">
            Don't have an account yet?{" "}
            <Button
              //   onClick={() => navigate("/SignUp")}

              color="primary"
            >
              Sign Up
            </Button>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
