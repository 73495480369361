export const BASE_URL = 'https://bootleggersdistribution.net';

export type configDataType = {
  token?: string;
  contentType?: 'application/json' | 'multipart/form-data';
};

export const REQUEST_CONFIG = (configData: configDataType) => ({
  headers: {
    Authorization: configData.token ? configData.token : '',
    'Content-Type': configData.contentType || 'application/json',
  },
});
