import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./Home.tsx";
import SignIn from "./SignIn.tsx";
import SignUp from "./SignUp.tsx";
import ForgotPassword from "./ForgotPassword.tsx";
import { useSelector } from "react-redux";
import { StoreState } from "../redux/reduxStore.ts";
import AllCategories from "./AllCategories.tsx";
import Cart from "./Cart.tsx";
import CategoryProduct from "./CategoryProduct.tsx";
import Orders from "./Orders.tsx";
import Product from "./Product.tsx";
import CheckOut from "./CheckOut.tsx";
const Layout = () => {
  const userData = useSelector((state: StoreState) => state.user);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/all-categories" element={<AllCategories />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/cart" element={<Cart />} />
        <Route
          path="/category-product/:id/:name"
          element={<CategoryProduct />}
        />
        <Route path="/orders" element={<Orders />} />
        <Route path="/checkOut" element={<CheckOut />} />
        <Route path="/product/:productId" element={<Product />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default Layout;
