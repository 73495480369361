import { PayloadAction } from "@reduxjs/toolkit";
import { CartSliceType } from "./slice.ts";

type stateType = CartSliceType;
type actionType = PayloadAction<Partial<CartSliceType>>;

export const setCart = (state: stateType, action: actionType) => {
  const { cartProducts, total } = action.payload;

  state.cartProducts =
    cartProducts !== undefined ? cartProducts : state.cartProducts;
  state.total = total !== undefined ? total : state.total;
};
