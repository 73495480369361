import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

interface IProps {
  onPressDecrease: () => void;
  onPressIncrease: () => void;
  quantity: number;
}

const ProductCounter: React.FC<IProps> = ({
  onPressDecrease,
  onPressIncrease,
  quantity,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        backgroundColor: "white",
        width: 90,
        height: 40,
        borderRadius: 30,
        alignItems: "center",
        marginBottom: 2,
        position: "relative",
        boxShadow: "2px -1px 38px -2px rgba(173,173,173,0.81)",
      }}
    >
      <IconButton
        onClick={onPressDecrease}
        sx={{
          padding: 1,
        }}
      >
        <RemoveIcon color="action" />
      </IconButton>
      <Typography
        sx={{
          color: theme.palette.text.primary,
          fontWeight: "bold",
        }}
      >
        {quantity}
      </Typography>
      <IconButton
        onClick={onPressIncrease}
        sx={{
          padding: 1,
        }}
      >
        <AddIcon color="action" />
      </IconButton>
    </Box>
  );
};

export default ProductCounter;
