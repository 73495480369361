import React, { useEffect, useState } from "react";
import {
  Typography,
  IconButton,
  Button,
  Box,
  ImageListItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { StoreState } from "../redux/reduxStore.ts";
import ProductCounter from "./ProductCounter.tsx";
import { ProductData } from "../services/product/types.ts";

interface IProps {
  onPress: () => void;
  src: string;
  onPressAdd: () => void;
  onPressDec: () => void;
  product: ProductData;
}

const AppProduct: React.FC<IProps> = ({
  onPress,
  src,
  onPressAdd,
  product,
  onPressDec,
}) => {
  const theme = useTheme();
  const cartData = useSelector((state: StoreState) => state.cart);
  const [isProductInCart, setIsProductInCart] = useState<
    { productData: ProductData; quantity: number } | undefined
  >(undefined);

  useEffect(() => {
    const index = cartData?.cartProducts?.findIndex(
      (item) => item.productData.id === product.id
    );
    if (index > -1) setIsProductInCart(cartData?.cartProducts[index]);
    else setIsProductInCart(undefined);
  }, [cartData?.cartProducts, product.id]);

  return (
    <Box data-aos="fade-up" sx={styling.mainBox} onClick={onPress}>
      <ImageListItem
        sx={{
          width: 200,
          height: 200,
          borderRadius: 1,
        }}
      >
        <img
          src={src}
          alt={product.name}
          loading="lazy"
          style={{
            width: "255px",
            height: "350px",
            objectFit: "cover",
            borderRadius: "4px",
          }}
        />
      </ImageListItem>
      <Box
        sx={{
          textAlign: "center",
          paddingBottom: 1,
        }}
      >
        <Typography variant="h6" sx={styling.heading}>
          {product.name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.text.secondary,
            fontFamily: "Spectral, serif",
            fontStyle: "italic",
            marginTop: 1,
          }}
        >
          {product.description}
        </Typography>
      </Box>
      {isProductInCart ? (
        <Box sx={styling.productCount}>
          <ProductCounter
            quantity={isProductInCart.quantity}
            onPressIncrease={onPressAdd}
            onPressDecrease={onPressDec}
          />
        </Box>
      ) : (
        <Box sx={styling.btnBox}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onPressAdd}
            sx={styling.btnCard}
          >
            <AddIcon />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default AppProduct;
const styling = {
  btnCard: {
    borderRadius: "50%",
    width: 40,
    height: 40,
    minWidth: "auto",
    backgroundColor: "#b7472a",
    color: "white",
    "&:hover": {
      backgroundColor: "#8e2b1e",
    },
  },
  btnBox: {
    position: "absolute",
    bottom: 8,
    right: 8,
    display: "flex",
    alignItems: "center",
  },
  productCount: {
    // position: "relative",
    bottom: 8,
    right: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  heading: {
    display: "block",
    fontFamily: "Spectral, serif",
    paddingTop: 3,
  },
  mainBox: {
    width: "255px",
    borderRadius: 2,
    margin: 2,
    paddingY: 6,
    position: "relative",
  },
};
