import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import OrdersItem from "./OrdersItem.tsx"; // Assuming OrdersItem is a separate component
import { format } from "date-fns";

const OrderCard = ({ order }) => {
  const theme = useTheme();

  const formattedDate = format(
    new Date(order.createDate),
    "MMM d, yyyy hh:mm a"
  );

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 2,
        marginY: 1,
        boxShadow: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginY: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            color: theme.palette.primary.main,
          }}
        >
          Order Id: {order.id}
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            color: theme.palette.primary.main,
          }}
        >
          {formattedDate}
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            color:
              order.orderStatus === "UnPaid"
                ? theme.palette.error.main
                : order.orderStatus === "Pending"
                ? theme.palette.grey[500]
                : theme.palette.success.main,
          }}
        >
          {order.orderStatus}
        </Typography>
      </Box>

      <Divider
        sx={{ width: "100%", marginY: 1 }}
        color={theme.palette.primary.main}
        orientation="horizontal"
      />

      <Typography
        sx={{
          fontSize: 16,
          fontWeight: 700,
          color: theme.palette.primary.main,
          marginTop: 2,
        }}
      >
        Order Items
      </Typography>

      <Box
        sx={{
          display: "flex",
          overflowX: "auto",
          paddingY: 1,
        }}
      >
        {order?.orderItems?.map((item, index) => (
          <OrdersItem orderItem={item} key={index} />
        ))}
      </Box>

      <Divider
        sx={{ width: "100%", marginY: 1 }}
        color={theme.palette.primary.main}
        orientation="horizontal"
      />

      {/* Uncomment this if you want to show the total amount */}
      {/* <Typography sx={{ fontSize: 16, fontWeight: 700, color: theme.palette.primary.main, marginTop: 2 }}>
        Total: ${order.totalAmount}
      </Typography> */}
    </Box>
  );
};

export default OrderCard;
