import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Typography,
  IconButton,
  Skeleton,
  Avatar,
  Container,
  Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CategoryCard from "../commponents/CategoryCard.tsx";
import images from "../constant/images.ts";
import { useDispatch, useSelector } from "react-redux";
import { StoreDispatch, StoreState } from "../redux/reduxStore.ts";
import { getAllProductCategory } from "../services/product/services.ts";
import {
  CategoryApiResponse,
  CategoryItem,
} from "../services/product/types.ts";
import { useNavigate } from "react-router-dom";

const AllCategories = () => {
  const [categories, setCategories] = useState<CategoryItem[]>([]);
  const userData = useSelector((state: StoreState) => state.user);
  const dispatch = useDispatch<StoreDispatch>();
  const [user, setUser] = useState({
    name: userData.customerName,
    email: userData.customerEmail,
    phone: userData.phoneno,
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleOnChange = (name: string, value: string) => {
    setUser({
      ...user,
      [name]: value,
    });
  };

  useEffect(() => {
    setLoading(true);
    getAllProductCategory({})
      .then((data: any) => {
        if (data) setCategories(data?.data as CategoryApiResponse["data"]);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          width: "100%",
          height: 90,
          boxShadow: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 30, left: 20 }}
          onClick={() => navigate("/")}
        >
          <ArrowBackIcon sx={{ color: "black", fontSize: 25 }} />
        </IconButton>
        <Avatar
          src={images.logo}
          alt="P"
          sx={{
            backgroundColor: "background.default",
            marginTop: 1,
            width: 56,
            height: 56,
          }}
        />
      </Box>

      <Container sx={{ paddingX: 3, paddingY: 2 }}>
        <Typography
          variant="h4"
          sx={{ color: "primary.main", marginBottom: 2 }}
        >
          Categories
        </Typography>
        <Box sx={{ paddingBottom: 5 }}>
          {categories?.length > 0 && !loading ? (
            categories.map((item, index) => (
              <CategoryCard
                heading={item.name}
                key={index}
                description={item.description}
                onPress={() =>
                  navigate(`/category-product/${item.pCategoryId}/${item.name}`)
                }
              />
            ))
          ) : (
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={60}
              sx={{ borderRadius: 1, backgroundColor: "grey.300" }}
            />
          )}
        </Box>
      </Container>
    </>
  );
};

export default AllCategories;
