import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  CircularProgress,
  Typography,
  IconButton,
} from "@mui/material";
import { Pagination } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ImageItem } from "../services/product/types";

import Banner from "../assets/Banner.png";
interface IProps {
  carouselData: ImageItem[];
  isLoading?: boolean;
  isLooped?: boolean;
  imageContainerStyle?: React.CSSProperties;
}

const BannerCarousel: React.FC<IProps> = ({
  carouselData,
  imageContainerStyle,
  isLooped = false,
}) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const theme = useTheme();
  const carouselRef = useRef<HTMLDivElement>(null);

  const handlePrevious = () => {
    setSlideIndex((prev) => (prev > 0 ? prev - 1 : carouselData.length - 1));
  };

  const handleNext = () => {
    setSlideIndex((prev) => (prev < carouselData.length - 1 ? prev + 1 : 0));
  };

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft =
        slideIndex * (carouselRef.current.scrollWidth / carouselData.length);
    }
  }, [slideIndex]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          margin: 0,
          padding: 0,
          marginTop: "-8px",
          position: "relative",
        }}
      >
        <img
          src={Banner}
          alt="Banner"
          style={{
            width:"100%",
            height: "750px",
            display: "block",
            objectFit: "cover",
          }}
        />

        <Box>
          <Typography
            sx={{
              position: "absolute",
              top: "40%",
              left: "20%",
              fontWeight: 700,
              fontStyle: "italic",
              transform: "rotate(-10deg)",
              width: "60%",
              color: "white",
              fontSize: { xs: "55px", md: "80px" },
              textAlign: "center",
              fontFamily: "'Spectral', serif",
            }}
          >
            Good Drink for Good Moments.
          </Typography>
        </Box>
      </Box>

      {/* <Box sx={{ width: "100%", mt: 2, position: "relative" }}>
        <Box
          ref={carouselRef}
          sx={{
            display: "flex",
            overflowX: "scroll",
            scrollBehavior: "smooth",
            scrollSnapType: "x mandatory",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          {carouselData.length > 0 ? (
            carouselData.map((item, index) => (
              <Box
                key={`${item.path}_${index}`}
                sx={{
                  flex: "0 0 auto",
                  width: "calc(100% - 40px)",
                  height: 300,
                  mx: 2,
                  borderRadius: 1,
                  overflow: "hidden",
                  position: "relative",
                  ...imageContainerStyle,
                }}
              >
                <img
                  src={`https://bootleggersdistribution.net${item.path}`}
                  alt={`Banner ${index}`}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Box>
            ))
          ) : (
            <Box
              sx={{
                width: "calc(100% - 40px)",
                height: 300,
                mx: 2,
                borderRadius: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>

        {carouselData.length > 1 && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={handlePrevious}
              sx={{ color: theme.palette.primary.main }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Pagination
              count={carouselData.length}
              page={slideIndex + 1}
              onChange={(event, value) => setSlideIndex(value - 1)}
              sx={{ mx: "auto" }}
            />
            <IconButton
              onClick={handleNext}
              sx={{ color: theme.palette.primary.main }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Box>
        )}
      </Box> */}
    </>
  );
};

export default BannerCarousel;
