import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { searchProductWithName } from "../services/product/services.ts";
import { ProductData } from "../services/product/types.ts";
import DrawerModal from "./DrawerModel.tsx";
import { FaFacebookF, FaInstagram, FaPhone, FaTwitter } from "react-icons/fa6";
import { LiaShoppingBagSolid } from "react-icons/lia";
import { Image } from "@mui/icons-material";
import images from "../constant/images.ts";

const Header = () => {
  const [value, setValue] = useState("");
  const [searchProduct, setSearchProduct] = useState<ProductData[]>([]);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (value.length > 0) {
      searchProductWithName(value, {}).then((data: any) => {
        if (data?.httpStatusCode === 200) {
          setSearchProduct(data.data);
        }
      });
    }
  }, [value]);
  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <DrawerModal />
      {/* <Box
        sx={{
          width: "100%",
          backgroundColor: "#a23f25",
          height: "25px",
          padding: "2px",
          overflowX: "auto",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            maxWidth: "1400px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0 auto",
            padding: 0,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "rgba(255, 255, 255, 0.6)",
              fontSize: "14px",
              fontFamily: "'Spectral', serif",
            }}
          >
            <FaPhone style={{ marginRight: "5px", fontSize: "14px" }} />
            +00 1234 567 &nbsp; &nbsp; email@email.com
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                marginRight: "25px",
                gap: "8px",
                alignItems: "center",
                color: "white",
                display: { display: "flex", xs: "none", md: "block" },
              }}
            >
              <FaFacebookF size={14} />
              <FaTwitter size={14} />
              <FaInstagram size={14} />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.6)",
                  fontSize: "14px",
                  fontFamily: "'Spectral', serif",
                  marginRight: "8px",
                }}
              >
                SIGN UP
              </Typography>
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.6)",
                  fontSize: "14px",
                  fontFamily: "'Spectral', serif",
                }}
              >
                LOGIN
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box> */}

      <Box>
        <Box
          sx={{
            bgcolor: "black",
            display: "flex",
            alignItems: "center",
            paddingX: "70px",
            paddingY: "6px",
            fontFamily: "'Spectral', serif",
          }}
        >
          <Box sx={{ flex: 2 }}>
            <img
              src={images.logo}
              width={100}
              height={100}
              style={{ objectFit: "contain" }}
            ></img>
            {/* <Typography
              sx={{
                fontSize: { xs: "18px", md: "24px" },

                fontWeight: "bold",
                color: "white",
                fontFamily: "'Spectral', serif",
                textTransform: "uppercase",
              }}
            >
              Liquor{" "}
              <span style={{ color: "rgba(255, 255, 255, 0.6)" }}>Store</span>
            </Typography> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flex: 1,
              flexDirection: { xs: "row-reverse", lg: "row" },
            }}
          >
            <List
              sx={{
                display: { xs: "none", lg: "flex" },
                fontSize: { xs: "12px", lg: "20px" },
                color: "rgba(255, 255, 255, 0.6)",
                fontFamily: "'Spectral', serif",
                textTransform: "uppercase",
                "& .MuiListItemText-primary": {
                  fontFamily: "'Spectral', serif",
                },
              }}
            >
              <ListItem>
                <ListItemText primary="Home" onClick={() => navigate("/")} />
              </ListItem>
              {/* <ListItem>
                <ListItemText primary="About" />
              </ListItem> */}
              <ListItem>
                <ListItemText primary="Products " />
              </ListItem>
              {/* <ListItem>
                <ListItemText primary="Blog " />
              </ListItem>
              <ListItem>
                <ListItemText primary="Contact " />
              </ListItem> */}
            </List>
            <Box
              sx={{
                display: { xs: "flex", lg: "none" },
              }}
            >
              <Typography
                onClick={() => setToggle(!toggle)}
                sx={{
                  fontFamily: "'Spectral', serif",
                  cursor: "pointer",
                  textTransform: "uppercase",
                  color: "white",
                }}
              >
                Menu
              </Typography>
            </Box>
            <Box>
              <LiaShoppingBagSolid
                size={20}
                style={{ color: "a23f25" }}
                onClick={() => navigate("/cart")}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {toggle && (
        <Box
          sx={{
            bgcolor: "black",
            color: "rgba(255, 255, 255, 0.6)",
            paddingX: "50px",
            fontFamily: "'Spectral', serif",
            display: { xs: "block", lg: "none" },
          }}
        >
          <List
            sx={{
              textTransform: "uppercase",
              "& .MuiListItemText-primary": {
                fontFamily: "'Spectral', serif",
              },
            }}
          >
            <ListItem>
              <ListItemText primary="Home" onClick={() => navigate("/")} />
            </ListItem>
            {/* <ListItem>
              <ListItemText primary="about " />
            </ListItem> */}
            <ListItem>
              <ListItemText primary="products " />
            </ListItem>
            {/* <ListItem>
              <ListItemText primary="blog " />
            </ListItem>
            <ListItem>
              <ListItemText primary="Contact " />
            </ListItem> */}
          </List>
        </Box>
      )}

      {/* <Box sx={{ width: "100%", backgroundColor: "black", padding: "10px 0" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: "1200px",
            margin: "0 auto",
            padding: "8px 20px",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "white",
              fontFamily: "'Spectral', serif",
              textTransform: "uppercase",
            }}
          >
            Liquor{" "}
            <span style={{ color: "rgba(255, 255, 255, 0.6)" }}>Store</span>
          </Typography>

          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              position: "absolute",
              left: "50%",
            }}
          >
            <LiaShoppingBagSolid
              size={23}
              style={{
                color: "#a23f25",
                marginRight: "8px",
                transform: "translateX(-50%)",
              }}
            />

            <Typography
              onClick={handleToggleMenu}
              sx={{
                color: "rgba(255, 255, 255, 0.5)",
                cursor: "pointer",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              MENU
            </Typography>
          </Box>



          <Box
            sx={{
              display: { xs: menuOpen ? "flex" : "none", md: "flex" },
              alignItems: "center",
              gap: "40px",
              margin: 0,
              fontFamily: "'Spectral', serif",
              letterSpacing: 1,
              textTransform: "uppercase",
              flexDirection: { xs: "column", md: "row" },
              position: { xs: "absolute", md: "static" },
              top: { xs: "50px", md: "auto" },
              left: { xs: 0, md: "auto" },
              right: { xs: 0, md: "auto" },
              backgroundColor: { xs: "black", md: "transparent" },
              padding: { xs: "10px", md: 0 },
              zIndex: 10,
            }}
          >
            {["Home", "About", "Products", "Blog", "Contact"].map((item) => (
              <li
                key={item}
                style={{
                  color: "rgba(255, 255, 255, 0.5)",
                  cursor: "pointer",
                  padding: "4px 0",
                  listStyle: "none",
                }}
              >
                {item}
              </li>
            ))}
          </Box>
        </Box>
      </Box> */}

      {/* <Box
        sx={{
          padding: 2,
          backgroundColor: theme.palette.background.paper,
          position: "relative",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={() => dispatch(userActions.setUser({ drawerOpen: true }))}
            color="primary"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ marginLeft: 1, fontWeight: 700 }}>
            Bootleggers Distribution
          </Typography>
        </Box>

        <TextField
          variant="outlined"
          placeholder="Search Products"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          fullWidth
          sx={{
            marginTop: 2,
            borderRadius: 2,
            "& .MuiOutlinedInput-root": {
              borderRadius: 30,
              backgroundColor: theme.palette.background.default,
            },
            "& .MuiInputBase-input": {
              padding: "10px 12px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        {value.length > 0 && (
          <ClickAwayListener onClickAway={() => setValue("")}>
            <Box
              sx={{
                position: "absolute",
                width: "calc(100% - 20px)",
                backgroundColor: theme.palette.background.paper,
                borderRadius: 1,
                boxShadow: 3,
                marginTop: 1,
                maxHeight: 300,
                overflowY: "auto",
                zIndex: 10000,
                maxWidth: "100%",
              }}
            >
              {searchProduct.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => navigate(`/product/${item.productId}`)}
                  sx={{ display: "flex", alignItems: "center", padding: 1 }}
                >
                  <ListItemIcon>
                    <SearchIcon />
                  </ListItemIcon>
                  <Typography sx={{ marginLeft: 2 }}>{item.name}</Typography>
                </MenuItem>
              ))}
            </Box>
          </ClickAwayListener>
        )}
      </Box> */}
    </>
  );
};

export default Header;
