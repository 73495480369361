import React, { useState } from "react";
import {
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  Box,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

type IProps = {
  label: string;
  editable?: boolean;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  secureTextEntry?: boolean;
  setSecureTextEntry?: () => void;
  errorMessage?: string;
  keyboardType?: string;
  leftIcon?: React.ReactNode;
};

const AuthInput: React.FC<IProps> = ({
  label,
  editable = true,
  value,
  onChange,
  secureTextEntry = undefined,
  setSecureTextEntry,
  errorMessage,
  keyboardType = "text",
  leftIcon,
}) => {
  const [isSecure, setIsSecure] = useState(secureTextEntry);

  const togglePasswordVisibility = () => {
    setIsSecure((prev) => !prev);
    if (setSecureTextEntry) setSecureTextEntry();
  };

  return (
    <Box sx={{ my: 1 }}>
      <TextField
        fullWidth
        label={label}
        value={value}
        onChange={onChange}
        type={isSecure ? "password" : "text"}
        variant="outlined"
        disabled={!editable}
        sx={{
          "& .MuiInputLabel-root": {
            color: "black",
          },
          "& .MuiInputBase-input": {
            fontSize: 16,
          },
          "& .MuiOutlinedInput-root": {
            height: 55,
          },
        }}
        InputProps={{
          startAdornment: leftIcon && (
            <InputAdornment position="start">{leftIcon}</InputAdornment>
          ),
          endAdornment: secureTextEntry !== undefined && (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility} edge="end">
                {isSecure ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={!!errorMessage}
        helperText={errorMessage}
      />
    </Box>
  );
};

export default AuthInput;
