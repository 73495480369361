import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Paper,
  Divider,
  CircularProgress,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { getOrderByCustomerId } from "../services/orders/services.ts";
import { OrderData } from "../services/orders/types.ts";
import OrderCard from "../commponents/OrderCard.tsx";
import CartButton from "../commponents/CartButton.tsx";
import { StoreDispatch, StoreState } from "../redux/reduxStore.ts";
import { useNavigate } from "react-router-dom";

const Orders: React.FunctionComponent = () => {
  const theme = useTheme();
  const dispatch = useDispatch<StoreDispatch>();
  const [loading, setLoading] = useState(false);
  const [orderState, setOrderState] = useState<"current" | "history">(
    "current"
  );
  const [orders, setOrders] = useState<OrderData[]>([]);
  const userData = useSelector((state: StoreState) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    getOrderByCustomerId(userData.customerId, {}).then((data: any) => {
      if (data?.httpStatusCode === 200) {
        setOrders(data.data);
      }
    });
  }, [userData.customerId]);

  return (
    <>
      <Paper
        elevation={7}
        sx={{
          backgroundColor: theme.palette.background.paper,
          width: "100%",
          height: 90,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 16, left: 16 }}
          onClick={() => navigate("/")}
        >
          <ArrowBackIcon
            sx={{ color: theme.palette.primary.contrastText, fontSize: 25 }}
          />
        </IconButton>
        <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
          Orders
        </Typography>
      </Paper>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          margin: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
          My Orders
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button
            variant={orderState === "current" ? "outlined" : "text"}
            sx={{
              marginX: 1,
              backgroundColor: theme.palette.background.paper,
              padding: 1,
              borderRadius: 1,
              borderColor:
                orderState === "current"
                  ? theme.palette.primary.main
                  : "transparent",
            }}
            onClick={() => setOrderState("current")}
          >
            Current
          </Button>
          <Button
            variant={orderState === "history" ? "outlined" : "text"}
            sx={{
              marginX: 1,
              backgroundColor: theme.palette.background.paper,
              padding: 1,
              borderRadius: 1,
              borderColor:
                orderState === "history"
                  ? theme.palette.primary.main
                  : "transparent",
            }}
            onClick={() => setOrderState("history")}
          >
            History
          </Button>
        </Box>
      </Box>

      <Box sx={{ paddingBottom: 10 }}>
        {loading ? (
          <CircularProgress
            sx={{ display: "block", margin: "auto", marginTop: 5 }}
          />
        ) : orders.filter((item) =>
            orderState === "current"
              ? item.orderStatus === "UnPaid"
              : item.orderStatus === "Paid"
          ).length ? (
          orders
            .filter((item) =>
              orderState === "current"
                ? item.orderStatus === "UnPaid"
                : item.orderStatus === "Paid"
            )
            .map((order, index) => <OrderCard order={order} key={index} />)
        ) : (
          <Typography sx={{ textAlign: "center", marginY: 5 }}>
            No Products Found
          </Typography>
        )}
      </Box>

      <CartButton />
    </>
  );
};

export default Orders;
