import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Data = {
  id: number;
  name: string;
  selected: boolean;
};

type IProps = {
  itemsData: Data[];
  setItemsData: (items: Data[]) => void;
  expanded: boolean;
  setExpanded: (e: boolean) => void;
  label: string;
};

const DropDownSelector: React.FunctionComponent<IProps> = ({
  itemsData,
  setItemsData,
  expanded,
  setExpanded,
  label,
}) => {
  // Create a function to generate the selected item text for the title
  const getSelectedItemsText = () => {
    const selectedItems = itemsData.filter((item) => item.selected);
    return selectedItems.length === 0
      ? "Select"
      : selectedItems.map((item) => item.name).join(", ");
  };

  const toggleItemSelection = (itemId: number) => {
    const updatedItems = itemsData.map((item) =>
      item.id === itemId
        ? { ...item, selected: !item.selected }
        : { ...item, selected: false }
    );
    setItemsData(updatedItems);

    // Close the accordion when an item is selected
    setExpanded(false);
  };

  return (
    <div>
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: "bold", color: "black", ml: 1 }}
      >
        {label}
      </Typography>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sx={{
          borderBottom: 2,
          borderColor: "primary.main",
          mb: 2,
          backgroundColor: "white",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content"
          id="panel-header"
          sx={{ backgroundColor: "white" }}
        >
          <Typography>{getSelectedItemsText()}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <List>
            {itemsData.map((item) => (
              <ListItem
                key={item.id}
                button
                onClick={() => toggleItemSelection(item.id)}
                sx={{ backgroundColor: "white" }}
              >
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DropDownSelector;
