import { AxiosGet, AxiosPost, AxiosPut } from "../../utils/apiMethods.ts";
import { BASE_URL, configDataType } from "../config.ts";
import {
  AddOrderResponse,
  GetOrdersByIdResponse,
  PostOrderData,
} from "./types.ts";

const setErrorMessage = (message: string) => ({
  title: "Product Service",
  message,
});
const getOrderByCustomerId_api = (customerId: string) =>
  `${BASE_URL}/api/Order/GetOrderByCustomerId?CustomerId=${customerId}`;
const addOrder_api = () => `${BASE_URL}/api/Order/AddOrder`;
const updateOrder_api = () => `${BASE_URL}/api/Order/UpdateOrder`;

export const getOrderByCustomerId = (
  customerId: string,
  config: configDataType
) =>
  AxiosGet<GetOrdersByIdResponse>(
    getOrderByCustomerId_api(customerId),
    config,
    setErrorMessage("getOrderByCustomerId Error")
  );
export const addOrder = (data: PostOrderData, config: configDataType) =>
  AxiosPost<AddOrderResponse>(
    addOrder_api(),
    config,
    setErrorMessage("addorder Error"),
    data
  );
export const updateOrder = (data: PostOrderData, config: configDataType) =>
  AxiosPut<AddOrderResponse>(
    updateOrder_api(),
    config,
    setErrorMessage("updateOrder Error"),
    data
  );
