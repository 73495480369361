import { AxiosGet, AxiosPost } from "../../utils/apiMethods.ts";
import { BASE_URL, configDataType } from "../config.ts";
import {
  AdvertisementApiResponse,
  CategoryApiResponse,
  CategoryProductApiResponse,
  GetProductByIdResponse,
  GetProductByName,
  ProductApiResponse,
} from "./types";

const setErrorMessage = (message: string) => ({
  title: "Product Service",
  message,
});
const getAllProduct_api = () => `${BASE_URL}/api/Product/GetAllProductAsync`;
const getProductsByCategoryId_api = (CategoryId: string) =>
  `${BASE_URL}/api/Product/GetProductsByCategoryId?CategoryId=${CategoryId}`;

const getAllProductCategory_api = () =>
  `${BASE_URL}/api/Product/GetAllProductCategory`;
const GetAllAdvertisement_api = () =>
  `${BASE_URL}/api/Advertisement/GetAllAdvertisement`;

const getProductByID_api = (id: string) =>
  `${BASE_URL}/api/Product/GetProductById?ProductId=${id}`;
const searchProductWithName_api = (name: string) =>
  `${BASE_URL}/api/Product/SearchProductWithName?Name=${name}`;

export const getAllProduct = (config: configDataType) =>
  AxiosGet<ProductApiResponse>(
    getAllProduct_api(),
    config,
    setErrorMessage("getAllProduct Error")
  );
export const getAllProductCategory = (config: configDataType) =>
  AxiosGet<CategoryApiResponse>(
    getAllProductCategory_api(),
    config,
    setErrorMessage("getAllProductCategory Error")
  );
export const getProductsByCategoryId = (
  CategoryId: string,
  config: configDataType
) =>
  AxiosGet<CategoryProductApiResponse>(
    getProductsByCategoryId_api(CategoryId),
    config,
    setErrorMessage("getProductsByCategoryId Error")
  );
export const getAllAdvertisement = (config: configDataType) =>
  AxiosGet<AdvertisementApiResponse>(
    GetAllAdvertisement_api(),
    config,
    setErrorMessage("getAllAdvertisement Error")
  );

export const getProductByID = (id: string, config: configDataType) =>
  AxiosGet<GetProductByIdResponse>(
    getProductByID_api(id),
    config,
    setErrorMessage("getProductByID Error")
  );
export const searchProductWithName = (name: string, config: configDataType) =>
  AxiosGet<GetProductByName>(
    searchProductWithName_api(name),
    config,
    setErrorMessage("getProductByID Error")
  );
