import { AxiosGet, AxiosPost, AxiosPut } from "../../utils/apiMethods.ts";
import { BASE_URL, configDataType } from "../config.ts";
import {
  SignInResponse,
  SignUpData,
  SignUpDataIndividual,
  UpdateProfile,
  UpdateProfileResponse,
} from "./types.ts";

const setErrorMessage = (message: string) => ({
  title: "Auth Service",
  message,
});

const Signin_api = (email: string, password: string) =>
  `${BASE_URL}/api/Customers/CustomerSignIn?Email=${email}&password=${password}`;
const SignUp_api = () => `${BASE_URL}/api/Customers/CustomerSignUp`;
const updateProfile_api = () => `${BASE_URL}/api/Customers/UpdateMoileCustomer`;
const deleteProfile_api = () => `${BASE_URL}/api/Customers/UpdateCustomer`;
export const signIn = (
  email: string,
  password: string,
  configData: configDataType
) =>
  AxiosPost<SignInResponse>(
    Signin_api(email, password),
    configData,
    setErrorMessage("SignInResponse method Error")
  );

export const signUp = (data: SignUpData, configData: configDataType) =>
  AxiosPost<SignInResponse>(
    SignUp_api(),
    configData,
    setErrorMessage("SignUpResponse method Error"),
    data
  );

export const updateProfile = (
  data: UpdateProfile,
  configData: configDataType
) =>
  AxiosPut<UpdateProfileResponse>(
    updateProfile_api(),
    configData,
    setErrorMessage("SignUpResponse method Error"),
    data
  );
export const deleteProfile = (
  data: UpdateProfile,
  configData: configDataType
) =>
  AxiosPut<UpdateProfileResponse>(
    deleteProfile_api(),
    configData,
    setErrorMessage("SignUpResponse method Error"),
    data
  );
export const signUpIdividual = (
  data: SignUpDataIndividual,
  configData: configDataType
) =>
  AxiosPost<SignInResponse>(
    SignUp_api(),
    configData,
    setErrorMessage("SignUpResponse method Error"),
    data
  );
