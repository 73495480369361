import { createSlice } from "@reduxjs/toolkit";
import { setCart } from "./reducer.ts";
import { ProductData } from "../../services/product/types.ts";
export type CartSliceType = {
  cartProducts: {
    productData: ProductData;
    quantity: number;
  }[];

  total: number;
};

export const cartSliceIntialState: CartSliceType = {
  cartProducts: [],

  total: 0,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState: cartSliceIntialState,
  reducers: { setCart },
});

export const cartActions = cartSlice.actions;
