import React, { useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Button,
  Container,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import { StoreDispatch, StoreState } from "../redux/reduxStore.ts";
import { ProductData } from "../services/product/types.ts";
import { removeItemFromCart } from "../redux/cart/actions.ts";
import images from "../constant/images.ts";
import CartProduct from "../commponents/CartProduct.tsx";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useNavigation } from "react-router-dom";

const Cart = () => {
  const theme = useTheme();
  const cartData = useSelector((state: StoreState) => state.cart);
  const userData = useSelector((state: StoreState) => state.user);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<StoreDispatch>();

  const onCheckOut = () => {
    navigate("/checkOut");
  };

  const removeItem = (product: ProductData) => {
    dispatch(removeItemFromCart({ productData: product }));
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{ backgroundColor: theme.palette.common.white, boxShadow: 3 }}
      >
        <Toolbar sx={{ justifyContent: "center", position: "relative" }}>
          <IconButton
            edge="start"
            color="inherit"
            sx={{
              position: "absolute",
              left: theme.spacing(2),
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
            }}
            onClick={() => navigate("/")}
          >
            <ArrowBackIcon sx={{ color: "black", fontSize: 25 }} />
          </IconButton>
          <Avatar
            src={images.logo}
            alt="Logo"
            sx={{
              backgroundColor: theme.palette.background.default,
              width: theme.spacing(7),
              height: theme.spacing(7),
              mt: 1,
            }}
          />
          <IconButton
            edge="end"
            color="inherit"
            sx={{
              position: "absolute",
              right: theme.spacing(2),
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
              borderRadius: 1,
              p: 1,
            }}
            onClick={() => navigate("/cart")}
          >
            <ShoppingCartIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Container sx={{ py: 2 }}>
        <Box sx={{ paddingBottom: 12 }}>
          {cartData.cartProducts.map((item, index) => (
            <CartProduct
              key={index}
              productData={item.productData}
              quantity={item.quantity}
              onPressTrash={() => removeItem(item.productData)}
            />
          ))}
        </Box>
      </Container>

      {cartData.cartProducts.length > 0 && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            position: "fixed",
            bottom: theme.spacing(2),
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "90%",
            justifyContent: "space-between",
            borderRadius: 1,
            boxShadow: 3,
            p: theme.spacing(1.5),
          }}
          endIcon={
            <ChevronRightIcon sx={{ color: theme.palette.common.white }} />
          }
          onClick={onCheckOut}
          disabled={loading}
        >
          <Typography>
            {cartData.cartProducts.length} Proceed to Checkout
          </Typography>
        </Button>
      )}
    </>
  );
};

export default Cart;
