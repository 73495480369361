import React from "react";
import { Typography, ButtonBase, Box, IconButton } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";

type IProps = {
  label: string;
  source?: any;
  onPress: () => void;
};

const AuthImagePicker: React.FunctionComponent<IProps> = ({
  label,
  source,
  onPress,
}) => {
  return (
    <>
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: "bold", color: "black", ml: 1, mt: 1 }}
      >
        {label}
      </Typography>
      <ButtonBase
        onClick={onPress}
        sx={{
          display: "block",
          width: "100%",
          textAlign: "center",
          mt: 1,
        }}
      >
        {source && source.mime ? (
          <Box
            component="img"
            src={`data:${source.mime};base64,${source.data}`}
            sx={{
              borderWidth: 1,
              borderColor: "primary.main",
              borderStyle: "solid",
              borderRadius: 1,
              width: "100%",
              height: 140,
              objectFit: "contain",
            }}
          />
        ) : (
          <Box
            sx={{
              borderWidth: 1,
              borderColor: "primary.main",
              borderStyle: "solid",
              borderRadius: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 140,
              width: "100%",
            }}
          >
            <IconButton>
              <UploadIcon fontSize="large" />
            </IconButton>
          </Box>
        )}
      </ButtonBase>
    </>
  );
};

export default AuthImagePicker;
