import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import { userSlice } from "./user/slice.ts";
import { cartSlice } from "./cart/slice.ts";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Persist configuration
const persistConfig = {
  key: "root",
  storage,
};

// Combine the slices
const rootReducer = combineReducers({
  user: userSlice.reducer,
  cart: cartSlice.reducer,
});

// Apply persistReducer to the combined reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with the persisted reducer
const reduxStore = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(reduxStore);
export default reduxStore;

export type StoreDispatch = typeof reduxStore.dispatch;
export type StoreState = ReturnType<typeof reduxStore.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  StoreState,
  unknown,
  Action<string>
>;
export type AsyncThunkConfig = {
  state: StoreState;
  dispatch: StoreDispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};
