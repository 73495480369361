import React from "react";
import { Button, IconButton, Typography, Box } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StoreState } from "../redux/reduxStore.ts";

const CartButton: React.FC = () => {
  const navigate = useNavigate();
  const cartData = useSelector((state: StoreState) => state.cart);

  return (
    <>
      {cartData?.cartProducts?.length > 0 && (
        <Button
          variant="contained"
          color="primary"
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            width: "calc(100% - 32px)",
            maxWidth: 360,
            borderRadius: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={() => navigate("/cart")}
        >
          <Typography variant="button">
            {`${cartData?.cartProducts?.length} View Cart`}
          </Typography>
          <IconButton
            sx={{
              backgroundColor: "white",
              borderRadius: 2,
              padding: 1,
            }}
          >
            <ChevronRightIcon color="primary" />
          </IconButton>
        </Button>
      )}
    </>
  );
};

export default CartButton;
