import React from "react";
import { Dialog, DialogContent, CircularProgress } from "@mui/material";

type IProps = {
  visible: boolean;
  onClose: () => void;
};

const AppDialog: React.FC<IProps> = ({ visible, onClose }) => {
  return (
    <Dialog open={visible} onClose={onClose}>
      <DialogContent
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};

export default AppDialog;
