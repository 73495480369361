import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

type IProps = {
  containerStyle?: React.CSSProperties;
  heading: string;
  onPress?: () => void;
  description: string;
};

const CategoryCard: React.FC<IProps> = ({
  containerStyle,
  heading,
  onPress,
  description,
}) => {
  const theme = useTheme();

  return (
    <Button
      onClick={onPress}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 2,
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 2,
        margin: 1,
        ...containerStyle,
      }}
    >
      <Box>
        <Typography
          variant="h6"
          component="div"
          sx={{
            color: theme.palette.primary.main,
            fontWeight: "bold",
          }}
        >
          {heading}
        </Typography>
      </Box>
      <ArrowForwardIcon sx={{ color: theme.palette.primary.main }} />
    </Button>
  );
};

export default CategoryCard;
