import React, { useEffect, useState } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
// import { useNavigate } from "react-router-dom";
import {
  AppHeading,
  BannerCarousel,
  HomeCategory,
  HomeProducts,
  CartButton,
} from "../commponents";
import {
  getAllAdvertisement,
  getAllProduct,
  getAllProductCategory,
} from "../services/product/services.ts";
import {
  CategoryItem,
  ImageItem,
  ProductData,
} from "../services/product/types";
import { useDispatch, useSelector } from "react-redux";
import { addtoCart, removeFromCart } from "../redux/cart/actions.ts";
import { StoreDispatch, StoreState } from "../redux/reduxStore.ts";
// import { Toast } from "react-toastify";
import Header from "../commponents/Header.tsx"; // Assuming you have a Header component
import { useNavigate } from "react-router-dom";

import Footer from "../commponents/Footer.tsx";
import InfoSection from "../commponents/InfoSection.tsx";

const Home: React.FC = () => {
  const [data, setData] = useState<ProductData[]>([]);
  const [categories, setCategories] = useState<CategoryItem[]>([]);
  const [banners, setBanners] = useState<ImageItem[]>([]);
  const dispatch = useDispatch<StoreDispatch>();
  const navigate = useNavigate();

  const addTocart = (product: ProductData) => {
    dispatch(addtoCart({ productData: product, quantity: 1 }))
      .unwrap()
      .then((message) => {
        // Toast.dismiss();
        // Toast(message, {
        //   type: message === "Product added" ? "success" : "error",
        // });
      });
  };

  const removeQuantity = (product: ProductData) => {
    dispatch(removeFromCart({ productData: product, quantity: 1 }))
      .unwrap()
      .then((message) => {
        // Toast.dismiss();
        // Toast(message);
      });
  };

  useEffect(() => {
    getAllProduct({}).then((data) => {
      if (data) setData(data.data);
    });
  }, []);

  useEffect(() => {
    getAllProductCategory({}).then((data) => {
      if (data) setCategories(data.data);
    });
  }, []);

  useEffect(() => {
    getAllAdvertisement({}).then((data) => {
      if (data) setBanners(data.data);
    });
  }, []);

  return (
    <>
      <Header />
      <Box>
        <BannerCarousel carouselData={banners} />
        {/* <InfoSection /> */}
        {/* <AppHeading
          heading="Categories"
          containerStyle={{ marginY: 2 }}
          onPress={() => navigate("/all-categories")}
        /> */}
        <HomeCategory categories={categories} />
        <AppHeading
          heading="Top Products"
          containerStyle={{ marginY: 2 }}
          viewAll={false}
        />
        <HomeProducts
          data={data}
          addTocart={addTocart}
          removeQuantity={removeQuantity}
        />
        {/* <AppHeading
          heading="All Products"
          containerStyle={{ marginY: 2 }}
          onPress={() => navigate("/category-product/allProducts/All Products")}
        /> */}
        {/* <HomeProducts
          data={data?.slice(0, 20)}
          addTocart={addTocart}
          removeQuantity={removeQuantity}
        /> */}
        <Footer />
      </Box>
      <CartButton />
    </>
  );
};

export default Home;
