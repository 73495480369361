import React, { useEffect, useState } from "react";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useDispatch, useSelector } from "react-redux";
import { addtoCart, removeFromCart } from "../redux/cart/actions.ts";
import { getProductByID } from "../services/product/services.ts";
import { useParams, useNavigate } from "react-router-dom";
import { StoreDispatch, StoreState } from "../redux/reduxStore.ts";
import { ProductData } from "../services/product/types.ts";

const Product = () => {
  const [productData, setProductData] = useState<ProductData | null>(null);
  const theme = useTheme();
  const cartData = useSelector((state: StoreState) => state.cart);
  const [isProductInCart, setIsProductInCart] = useState<
    { productData: ProductData; quantity: number } | undefined
  >(undefined);
  const dispatch = useDispatch<StoreDispatch>();
  const navigate = useNavigate();
  const { productId } = useParams();

  const addTocart = (product: ProductData) => {
    dispatch(addtoCart({ productData: product, quantity: 1 }))
      .unwrap()
      .then((message) => {
        // Toast.hideAll();
        // Toast.show(message, {
        //   type: message === 'Product added' ? 'normal' : 'danger',
        // });
      });
  };
  const removeQuantity = (product: ProductData) => {
    dispatch(removeFromCart({ productData: product, quantity: 1 }))
      .unwrap()
      .then((message) => {
        // Toast.hideAll();
        // Toast.show(message);
      });
  };

  useEffect(() => {
    const index = cartData.cartProducts.findIndex(
      (item) => item.productData.id === Number(productId)
    );
    if (index > -1) setIsProductInCart(cartData.cartProducts[index]);
    else setIsProductInCart(undefined);
  }, [cartData.cartProducts, productId]);

  useEffect(() => {
    getProductByID(String(productId || ""), {}).then((data) => {
      if (data) {
        setProductData(data.data);
      }
    });
  }, [productId]);

  return (
    <Box sx={{ padding: 2, position: "relative" }}>
      <Box sx={{ position: "absolute", top: 20, left: 20 }}>
        <IconButton onClick={() => navigate(-1)} color="primary">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" color="primary" sx={{ marginLeft: 2 }}>
          Product Details
        </Typography>
      </Box>

      <Box
        sx={{
          marginTop: 10,
          backgroundColor: theme.palette.background.paper,
          borderRadius: 2,
          padding: 2,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: 300,
            backgroundColor: theme.palette.common.white,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: 1,
            borderColor: theme.palette.grey[400],
            borderRadius: 2,
            marginBottom: 2,
          }}
        >
          {productData && productData.images?.length > 0 && (
            <img
              src={`https://bootleggersdistribution.net${productData.images[0].path}`}
              alt={productData.name}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" color="textPrimary">
            {productData?.name}
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            {isProductInCart ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  onClick={() => productData && removeQuantity(productData)}
                >
                  <RemoveIcon />
                </IconButton>
                <Typography>{isProductInCart.quantity}</Typography>
                <IconButton
                  onClick={() => productData && addTocart(productData)}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => productData && addTocart(productData)}
                sx={{ borderRadius: "50%", minWidth: 0, padding: 1 }}
              >
                <AddIcon />
              </Button>
            )}
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginLeft: 1, fontWeight: "bold" }}
            >
              {productData?.quantity && productData?.quantity > 0
                ? "Available in Stock"
                : "Out Of Stock"}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box sx={{ marginBottom: 8 }}>
          <Typography variant="h6" color="textPrimary">
            Product Detail
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ whiteSpace: "pre-wrap" }}
          >
            {productData?.description}
          </Typography>
        </Box>
      </Box>

      <IconButton
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          borderRadius: "50%",
          padding: 2,
        }}
      >
        <ShoppingCartIcon />
      </IconButton>
    </Box>
  );
};

export default Product;
